import { BaseTypes } from "Components/types/helpers/Slide";
import { getPlaySlideProperties } from "Components/types/helpers/slideTypeHelper";
import { PlaySlideType, SendshakeRequests } from "Types/playTypes";
import { sendshakeRequest } from "./api";
import { getFromCookies } from "./cookieHelper";
import { TOKEN_NAME } from "./globals";

const addinNamesByEnv = {
	development: `${process.env.ADDIN_NAME}-Beta`,
	production: `${process.env.ADDIN_NAME}`
}

export const stopSession = async ({ sessionId, sessionRunId }) => {
	return sendshakeRequest({
		request: SendshakeRequests.StopSession,
		session_id: sessionId,
		session_run_id: sessionRunId,
		local_session_control: 3, // Votes
		email_presentation_results: 1,
	})
}

export const openVote = async voteId => {
	return sendshakeRequest({
		request: SendshakeRequests.OpenVote,
		vote_id: voteId,
	})
}

export const openMessageRound = async messageRoundId => {
	return sendshakeRequest({
		request: SendshakeRequests.OpenMessageRound,
		message_round_id: messageRoundId
	})
}

export const closeVote = async (voteId: number) => {
	return sendshakeRequest({
		request: SendshakeRequests.CloseVote,
		vote_id: voteId,
	})
}

export const closeMessageRound = async (messageRoundId: number) => {
	return sendshakeRequest({
		request: SendshakeRequests.CloseMessageRound,
		message_round_id: messageRoundId
	})
}

export const userOpinion = async ({ email, rating, opinion }) => {
	return sendshakeRequest({
		request: SendshakeRequests.UserOpinion,
		product_name: addinNamesByEnv[process.env.NODE_ENV], // Change to WebQuiz + insert in dev/live
		email,
		rating,
		opinion: '[WebPresentations] ' + opinion
	})
}

export const sendStopSessionBeacon = (sessionId, sessionRunId) => {

	const requestData = {
		request: SendshakeRequests.StopSession,
		session_id: sessionId,
		session_run_id: sessionRunId,
		local_session_control: 3, // Votes
		email_presentation_results: 1,
		token: getFromCookies(TOKEN_NAME)
	}

	const queryPrefix = 'query?data='

	let fullUrl = `${process.env.SENDSHAKE_URL}${queryPrefix}${encodeURIComponent(JSON.stringify(requestData))}`

	navigator.sendBeacon(fullUrl);
}

export const closeSlide = async (slide: PlaySlideType) => {

	const slideProperties = getPlaySlideProperties(slide)

	const { Vote, MessageRound } = BaseTypes

	if(slideProperties.baseType === Vote) {

		return closeVote(slide.id)
	}

	if(slideProperties.baseType === MessageRound) {

		return closeMessageRound(slide.id)
	}
}

export const calculateVoteResults = async (voteId: number, maxAudienceSize: number) => {

	return sendshakeRequest({
		request: SendshakeRequests.CalculateVoteResults,
		vote_id: voteId,
		max_audience_size: maxAudienceSize,
	});
}