
export enum SlideTypes {
    MultipleChoice = 'mpc',
    Quiz = 'quiz',
    Wordcloud = 'wordcloud',
    QA = 'qa',
    OpenEnded = 'open_ended',
    Points = 'points',
    Content = 'content',
    Title = 'title',
    Image = 'image',
    Video = 'video',
    Quote = 'quote',
    Countdown = 'quiz_countdown',
    TitleAndSubtitle = 'title_and_subtitle',
    Shape = 'shape',
    Table = 'table',
    LineGraph = 'line_graph',
    BarGraph = 'bar_graph',
    Podium = 'podium',
     /** QR code slide */
    Explanation = 'explanation',
    Leaderboard = 'leaderboard',
}

export enum SlideShapeType {
    Image = 'image',
    Video = 'video',
    Title = 'title',
    Content = 'content',
    Table = 'table',
    BarGraph = 'bar_graph',
    LineGraph = 'line_graph',
    /** The `"` symbol before the quote slide title */
    QuoteSymbol = 'quote_symbol',
    /** The user symbol after the quote slide title */
    QuoteAuthorSymbol = 'quote_author_symbol',
    /** Countdown indicator for countdown slide */
    QuizCountdown = 'quiz_countdown',
    /** Container for displaying participant messages */
    MessagesPanel = 'messages',
    /** Container for displaying wordcloud messages */
    WordcloudPanel = 'wordcloud',
    /** Quiz options */
    MpcOptions = 'mpc_options',
    /** Company logo */
    Logo = 'logo',
    /** Leaderboard */
    Leaderboard = 'leaderboard',
    /** Podium */
    Podium = 'podium',
    /** Explanation */
    Explanation = 'explanation',
}

export interface SlideShape {
    id?: number;
    slideId: number;
    x: number;
    y: number;
    height: number;
    width: number;
    contents: string;
    type: SlideShapeType;
    /** A stringified JSON object containing JSS styles */
    jss: string;
    filename: string;
}

/**
 * These shapes are fixed in place and will never reposition
 */
export const fixedSlideShapes = [
    SlideShapeType.Logo,
    SlideShapeType.Podium,
    SlideShapeType.Leaderboard,
];

/**
 * These shapes are not affected by the positioning/aligning algorithm. But are affected by the
 * re-positioning script ran while changing layout types
 */
export const staticSlideShapes = [
    SlideShapeType.Video,
    SlideShapeType.Image,
];

/** These shapes should fill the remaining height of the shape */
export const fullSizeSlideShapes = [
    SlideShapeType.BarGraph,
    SlideShapeType.Table,
    SlideShapeType.LineGraph,
    SlideShapeType.WordcloudPanel,
    SlideShapeType.MessagesPanel,
    SlideShapeType.MpcOptions,
];

export enum SlideInputFontSize {
    Large = 56,
    Medium = 48,
    Small = 40,
    XSmall = 32,
    XXSmall = 28,
    XXXSmall = 24,
}

/**
 * These slide types are interactive and have some type of animations/behaviour tied to them
 */
export const interactiveSlideTypes = [
    SlideTypes.MultipleChoice,
    SlideTypes.Quiz,
    SlideTypes.Wordcloud,
    SlideTypes.QA,
    SlideTypes.OpenEnded,
    SlideTypes.Points,
];

/**
 * question start delay per question type
 */
export const interactiveSlideTypesStartDelays: Partial<Record<SlideTypes, number>> = {
    [SlideTypes.MultipleChoice]:    3500,
    [SlideTypes.Points]:            3500,
    [SlideTypes.Quiz]:              7000,
    [SlideTypes.Wordcloud]:         3500,
    [SlideTypes.QA]:                3500,
    [SlideTypes.OpenEnded]:         3500,
}