import { clearAppState, setLoginCallback, signOut, toggleLoginScreen } from "Actions/appActions";
import * as authActions from 'Actions/authActions';
import { clearOverviewState } from "Actions/overviewActions";
import { clearPlayState } from "Actions/playActions";
import { clearQuizState } from "Actions/quizActions";
import { post } from 'Scripts/api';
import { getFromCookies } from "Scripts/cookieHelper";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { REFRESH_TOKEN_NAME, TOKEN_NAME } from "../scripts/globals";
import useUrl from "./useUrl";


export enum UserRoles {
	User = 'user',
	Manager = 'manager',
	Admin = 'admin',
	AccountOwner = 'accountowner',
	SuperAdmin = 'superadmin',
}

export enum UserRoleFriendlyNames {
	user = 'Presenter',
	manager = 'Manager',
	admin = 'Administrator',
	accountowner = 'License Holder',
	superadmin = 'Super Administrator',
}

export const useAuthentication = () => {

	const dispatch = useDispatch();

	const { enqueueSnackbar } = useSnackbar();

	const history = useHistory();

	const urls = useUrl();

	const authToken = getFromCookies(TOKEN_NAME);

	const refreshToken = getFromCookies(REFRESH_TOKEN_NAME);

	const logout = () => {

		dispatch(clearQuizState())

		dispatch(clearPlayState())

		dispatch(clearAppState())

		dispatch(clearOverviewState())

		dispatch(signOut());

		enqueueSnackbar('Logged out!', { variant: 'info' })

		history.push(urls.presentationOverview);
	}

	const openLogin = () => {

		dispatch(toggleLoginScreen(true));
	}

	const closeLogin = () => {

		dispatch(toggleLoginScreen(false));
	}

	const addLoginCallback = (loginCallback: () => void) => {

		dispatch(setLoginCallback(loginCallback));
	}

	const setDeleteAccountDialogOpen = (isOpen: boolean) => {

		dispatch(authActions.setDeleteAccountDialogOpen(isOpen));
	}

	const setDeleteAccountLoading = (isLoading: boolean) => {

		dispatch(authActions.setDeleteAccountDialogOpen(isLoading));
	}

	const deleteAccount = async () => {

		try {

			dispatch(authActions.setDeleteAccountLoading(true));

			const response = await post('users/delete-user');

			if(!response?.error) {

				logout();

				enqueueSnackbar('Your account has been deleted.', { variant: 'success' });

			} else {

				enqueueSnackbar(response.error, { variant: 'warning' });

			}

		} catch (error) {

			console.warn(error);

		} finally {

			dispatch(authActions.setDeleteAccountLoading(false));

			dispatch(authActions.setDeleteAccountDialogOpen(false));
		}
	}

	const setExternalAuthenticationKey = (key: string | null) => {

		dispatch(authActions.setExternalAuthenticationKey(key));
	}

	const setExternalAuthenticationConfirmed = (isConfirmed: boolean) => {

		dispatch(authActions.setExternalAuthenticationConfirmed(isConfirmed));
	}

	return {
		authToken,
		refreshToken,
		openLogin,
		closeLogin,
		addLoginCallback,
		logout,
		setDeleteAccountDialogOpen,
		setDeleteAccountLoading,
		deleteAccount,
		setExternalAuthenticationKey,
		setExternalAuthenticationConfirmed,
	}
}