import { CookieKeys, WEB_PRESENTATIONS_CLIENT_ID } from 'Scripts/globals';
import { APIResponseData } from "./api";
import { addToCookies, getFromCookies } from "./cookieHelper";
import { REFRESH_TOKEN_NAME, TOKEN_EXPIRES_NAME, TOKEN_NAME } from "./globals";
import { getFromLocalStorage } from "./localStorage";
import { getNewrelic } from './newrelicHelper';

enum ApiRequestMethods {
    PATCH = 'PATCH',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    GET = 'GET',
}
interface APIRequestProps {
    method: keyof typeof ApiRequestMethods;
    route: string;
    body?: Object;
    overwriteBaseUrl?: boolean;
}

const errorStatusses = {
    400: "Invalid Request (400)",
    401: "Unauthorized Request (401)",
    404: "Not Found (404)",
    500: "Internal Server Error (500)",
    502: "Bad Gateway (502)",
    504: "Gateway Timeout (504)",
}

const isValidReturnStatus = (status: number) => {

    return status >= 200 && status <= 308;
}

export const sendshakeCall = async (data: {}) => {

    const SUCCESS_STATE = 'success'

    const ERROR_STATE = 'error'

    const queryPrefix = 'query?data='

    const token = getFromCookies(TOKEN_NAME);

    if (token) {

        data.token = token;
    }

    let fullUrl = `${process.env.SENDSHAKE_URL}${queryPrefix}${encodeURIComponent(JSON.stringify(data))}`

    try {

        const response = await fetch(fullUrl);

        const responseClone = response.clone()

        const textData = await responseClone.text();

        const splitResponse = textData.split('-');

        const [resultState, ...resultData] = splitResponse

        if (resultState === SUCCESS_STATE) {

            return resultData.map(item => atob(item))
        }

        if (resultState === ERROR_STATE) {

            getNewrelic(nr => nr.noticeError(JSON.stringify(resultData)));

            return { [ERROR_STATE]: resultData }
        }

        const blobData = await response.blob();

        return blobData

    } catch (error) {

        getNewrelic(nr => nr.noticeError(error));

        console.warn({ error })

        return { error }
    }
}


export const baseRequest = async ({ url }) => {

    try {

        const response = await fetch(url);

        const jsonData = await response.json();

        return jsonData

    } catch (error) {

        return { error }
    }
}

/*
    Pass through to check if the auth token expires soon.
    If so, pre-emptively refresh the auth token

    requester expects an API handler function
*/
export const refreshPassthrough = async (apiRequestProps: APIRequestProps, requester: (props: APIRequestProps) => void) : Promise<any> => {

    const authTokenExpiration = getFromCookies(TOKEN_EXPIRES_NAME);
    const refreshToken = getFromCookies(REFRESH_TOKEN_NAME);

    if (Boolean(authTokenExpiration)) {

        if ((Number(authTokenExpiration) - (Date.now() * .001 >> 0)) < 60) {

            const refreshResponse = await request({
                method: ApiRequestMethods.POST,
                route: 'authentication/refresh',
                body: {
                    refresh_token: refreshToken,
                    email: getFromLocalStorage("login_email") || ''
                },
            });

            if(!refreshResponse.error) {

                console.warn('token refreshed');
                /** in 1 month */
				const expDate = new Date().getTime() + (1000 * 60 * 60 * 24 * 30);

                addToCookies(TOKEN_EXPIRES_NAME, refreshResponse.expires, expDate);
               
                addToCookies(TOKEN_NAME, refreshResponse.token, expDate);

                addToCookies(CookieKeys.AuthToken, refreshResponse.token, refreshResponse.expires * 1000);

                addToCookies(REFRESH_TOKEN_NAME, refreshResponse.refreshToken, expDate);
            }
        }
    }

    return requester(apiRequestProps);
}

export const request = ({
    method,
    route,
    body = {},
    overwriteBaseUrl = false
}: APIRequestProps) : Promise<APIResponseData> => {

    /* Append the general client_id for all posts requests */
    if ( method === ApiRequestMethods.POST ) {

        body['client_id'] = WEB_PRESENTATIONS_CLIENT_ID
    }

    const xhr = new XMLHttpRequest();

    let fullUrl = `${process.env.API_URL}${route}`

    if (overwriteBaseUrl) {
        /* Use the route as URL if overwriteBaseUrl = true */
        fullUrl = route;
    }

    xhr.open(method, fullUrl, true);

    xhr.setRequestHeader('Content-Type', 'application/json');
    /** Add the client ID as header */
    xhr.setRequestHeader('X-Sendsteps-Client-Id', WEB_PRESENTATIONS_CLIENT_ID);

    const authToken = getFromCookies(TOKEN_NAME);

    if (authToken) {
        /* Append authToken to header */
        xhr.setRequestHeader('Authorization', `Bearer ${authToken}`);
    }

    return new Promise((resolve) => {

        try {

            xhr.onerror = () => {

                getNewrelic(nr => nr.noticeError(new Error(xhr.response)));

                resolve({ error: xhr.response });
            }

            xhr.onreadystatechange = () => {

                if (xhr.readyState === 4 && xhr.status === 0) {
                    /* Cors */
                    resolve({ error: "Something went wrong with the request. (CORS)" });
                }
            }

            xhr.send(JSON.stringify(body));

            xhr.addEventListener('load', () => {

                if (isValidReturnStatus(xhr.status)) {

                    /* Parse the response to JSON and resolve the Promise */
                    const parsedResponse = JSON.parse(xhr.response)
                    /* If the response is a string, something probably went wrong */
                    if(typeof parsedResponse === 'string') {
                        /* If the token is expired and we have a refresh token stored, reload the page */
                        resolve({ error: parsedResponse });
                        return;
                    }

                    resolve(parsedResponse)

                } else {
                    console.warn(`Received ${xhr.status} response`);
                    /* Reject the Promise (Invalid status) */
                    const errorResponse = JSON.parse(xhr.response);

                    getNewrelic(nr => nr.noticeError(new Error(xhr.response)));

                    const errorMessage = errorResponse?.message || errorResponse?.error || errorStatusses[xhr.status] || "Something went wrong.";
                    resolve({ error: errorMessage })
                }
            })

        } catch (error) {

            getNewrelic(nr => nr.noticeError(error));

            console.warn(error);
            /* Reject the Promise */
            resolve({ error })
        }
    })
}

export const downloadResource = (route: string, resourceFilename: string) : Promise<void> => {

    return new Promise((resolve, reject) => {

        const fullUrl = process.env.API_URL + route;

        fetch(fullUrl, {
            method: ApiRequestMethods.GET,
            headers: new Headers({
                "Authorization": "Bearer " + getFromCookies(TOKEN_NAME)
            })
        })
        .then(successResponse => {
            if (successResponse.status != 200) {

                throw new Error('Failed to download resource ' + successResponse.statusText)
            } else {
                return successResponse.blob();
            }
            },
        )
        .then(blob => {

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            /* Simulate the user clicking on an a tag and downloading the blob */
            a.href = url;
            a.download = resourceFilename;
            document.body.appendChild(a);
            a.click();
            a.remove();

            resolve();
        })
        .catch(error => {

            getNewrelic(nr => nr.noticeError(error));

            reject({ error });
        })
    })
}