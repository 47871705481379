// @ts-nocheck
/* Map() polyfill for IE */
import 'core-js/es/map';
/* Set() polyfill for IE */
import 'core-js/es/set';
/* navigator.sendBeacon() polyfill for IE to handle tab close events */
import 'navigator.sendbeacon';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { addToCookies } from 'Scripts/cookieHelper';
import { CookieKeys, REFRESH_TOKEN_NAME, TOKEN_EXPIRES_NAME, TOKEN_NAME } from 'Scripts/globals';
import { getFromLocalStorage, removeFromLocalStorage } from 'Scripts/localStorage';
import Application from "./components/app/Application";

const setCookiesIfLocalStorageVarsAreSet = (variables) => {
    const expDate = new Date().getTime() + (1000 * 60 * 60 * 24 * 30);
    variables.forEach((variable) => {
       const varFromLocalStorage = getFromLocalStorage(variable);
         if(varFromLocalStorage){
            addToCookies(variable, varFromLocalStorage, expDate);
            removeFromLocalStorage(variable);
        } 
    })
}

const checkAuthVariables = async () => {
  try {
    setCookiesIfLocalStorageVarsAreSet([
      TOKEN_EXPIRES_NAME, TOKEN_NAME,
      REFRESH_TOKEN_NAME, 
      CookieKeys.AuthToken
    ]);
  } catch (e) {
    console.warn(e);
  }
};

(async () => {
  try {
    await checkAuthVariables();
    
    const rootElement = document.getElementById('root');
    const root = createRoot(rootElement);
    
    root.render(<Application />);
  } catch (error) {
    console.error('Failed to checkAuthVariables:', error);
  }
})();