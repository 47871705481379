import { Divider, Toolbar } from '@mui/material';
import { Box } from '@mui/system';
import BackToPresentationButton from 'Components/beta/common/buttons/presentation/BackToPresentationButton';
import { useAppRouting } from 'Hooks/useAppRouting';
import { useLayout } from 'Hooks/useLayout';
import usePresentation from 'Hooks/usePresentation';
import useUrl from 'Hooks/useUrl';
import { getFromCookies } from 'Scripts/cookieHelper';
import { testIds } from 'Scripts/cypressTestIds';
import { TOKEN_NAME } from 'Scripts/globals';
import { SendstepsIntegrations } from 'Types/appTypes';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SummaryControls from './controls/SummaryControls';
import SummaryOptionsContainer from './options/SummaryOptionsContainer';
import { classes } from './style.css';
import PresentationTitleContainer from './title/PresentationTitleContainer';


const StartPresentationSummary = () => {

	const {
		setSelectedSettingsIntegration,
	} = useLayout();

	const { presentationId } = useAppRouting();

	const {
		fetchPresentationDetails,
	} = usePresentation();

	const urls = useUrl();

	const history = useHistory();

	const loadPresentation = async () => {

		if (!getFromCookies(TOKEN_NAME)) {

			history.push(urls.presentationOverview);

		} else {

			if (presentationId) {

				await fetchPresentationDetails(presentationId);

			} else {

				history.push(urls.presentationOverview);
			}
		}
	}

	useEffect(() => {
		/* Load the presentation from the api on mount */
		loadPresentation()

		/* Show the default web settings */
		setSelectedSettingsIntegration(SendstepsIntegrations.WebApp)
	}, [])

	return (
		<div className={classes.editorContent} data-testid={testIds.EDITOR_SETTINGS_CONTAINER}>
			<Toolbar className={classes.editToolbar}>
				<div className={classes.editorToolbarContainer}>
					<div className={classes.backButtonContainer}>
						<BackToPresentationButton
							trackScreen="sendsteps_summary"
						/>
					</div>
				</div>
			</Toolbar>
			<Divider className={classes.editToolbarDivider} />
			{/* Actual summary */}
			<Box className={classes.presentationSummary}>
				<PresentationTitleContainer />
				<SummaryControls />
				<SummaryOptionsContainer />
			</Box>
		</div>
	);
};

export default StartPresentationSummary;