import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Box, Chip, Slide, alpha, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import * as appActions from 'Actions/appActions';
import { saveUserData, setAuthLoading, setLoginCallback, toggleLogin } from 'Actions/appActions';
import SocialLoginGoogle from 'Assets/images/google-icon.svg';
import SocialLoginLinkedin from 'Assets/images/linkedin.svg';
import AppButton, { AppButtonVariant } from 'Atomic/atoms/Button/AppButton';
import AppInput from 'Atomic/atoms/Input/AppInput';
import AppLink from 'Atomic/atoms/Link/AppLink';
import AppDropdown from 'Atomic/molecules/AppDropdown/AppDropdown';
import ErrorText from 'Components/beta/common/text/error/ErrorText';
import ButtonLoader from 'Components/common/util/button_loader/ButtonLoader';
import { useInitApplication } from 'Hooks/useInitApplication';
import { useTranslations } from 'Hooks/useTranslations';
import useUrl from 'Hooks/useUrl';
import { get, post } from 'Scripts/api';
import { addToCookies, getFromCookies, removeFromCookies } from 'Scripts/cookieHelper';
import { testIds } from 'Scripts/cypressTestIds';
import { CookieKeys, REFRESH_TOKEN_NAME, TOKEN_NAME, WEB_PRESENTATIONS_CLIENT_ID } from 'Scripts/globals';
import { DataElAction, DataElPosition, DataElScreen, DataElType } from 'Scripts/measurementsGAHelper';
import { getNewrelic } from 'Scripts/newrelicHelper';
import { isValidEmail, isValidLength } from 'Scripts/validator';
import { AppState, ExternalAuthParams, IdentityProviderParams, LoginParams, UTM } from 'Types/appTypes';
import { AuthSsoUrl, AuthState } from 'Types/authTypes';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import SignupBackdrop from '../shared/backdrop/SignupBackdrop';
import SendstepsLogo from '../shared/image/logo/SendstepsLogo';
import AuthPendingComponent from './auth_pending/AuthPendingComponent';
import AuthSuccessComponent from './auth_success/AuthSuccessComponent';
import { classes } from './style.css';

const LoginDialog = () => {

    const {
        translatePlaceholder,
    } = useTranslations();

    const history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();

    const { fetchAppData } = useInitApplication();

    const emailFieldRef = useRef<HTMLInputElement>();

    const otpFieldRef = useRef<HTMLInputElement>();

    const urls = useUrl();

    const [email, setEmail] = useState<string | null>(null);

    const [emailPlaceholder, setEmailPlaceholder] = useState<string>('');

    const [emailFieldTouched, setEmailFieldTouched] = useState<boolean>(false);

    const [identityCheckLoading, setIdentityCheckLoading] = useState<boolean>(false);

    const [emailError, setEmailError] = useState<string | null>(null);

    const [otp, setOtp] = useState<string>('');

    const [otpError, setOtpError] = useState<string | null>(null);

    const [generalError, setGeneralError] = useState<string | null>(null);

    const [ssoUrls, setSsoUrls] = useState<AuthSsoUrl[] | null>(null);

    const loginCallback = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).loginCallback);

    const currentUser = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).currentUser);

    const authLoading = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).authLoading);

    const loginLoading = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).loginLoading);

    const [showLoginButton, setShowLoginButton] = useState<boolean>(false);

    const [verificationLoading, setVerificationLoading] = useState<boolean>(false);

    const externalAuthenticationKey = useSelector((state: RootStateOrAny) => (state.authReducer as AuthState).externalAuthenticationKey);

    const GETParams = new URLSearchParams(window.location.search);

    const externalAuthKey = GETParams.get(UTM.ExternalAuthKey);

    const validateEmailPlaceholder = () => {

        let error = null;

        const isValid = isValidEmail(emailPlaceholder as string);

        if (!isValid) {

            error = translatePlaceholder('INFO_FORM_EMAIL_INCORRECT');

            focusEmailField();
        }

        setEmailError(error);

        return isValid;
    }

    const validateOtp = () => {

        let error = null;

        const isValidOtp = isValidLength(otp, 6, 6);

        if (!isValidOtp) {

            error = (translatePlaceholder('FORM_OTP_CODE_ERR') + translatePlaceholder('OR_ENTER_YOUR_PASSWORD')) ;

            focusOtpField();
        }

        setOtpError(error);

        return isValidOtp;
    }

    const attemptLogin = () => {

        if (isValidEmail(email as string) && isValidLength(otp, 6)) {

            login();

        } else {

            validateEmailPlaceholder(emailPlaceholder);

            validateOtp();
        }
    }

    const checkIdentityProvider = async (identity: string) => {

        try {

            setIdentityCheckLoading(true);
            setSsoUrls(null);

            const requestParams: IdentityProviderParams = {
                email: identity
            }

            if (Boolean(externalAuthKey)) {

                requestParams.extraParams = {
                    externalAuthKey: GETParams.get(UTM.ExternalAuthKey),
                }
            }

            const response = await post('identity/check-idp', requestParams);

            if (!response.error) {

                if (Boolean(response.ssoLoginUrls)) {

                    if (response.ssoLoginUrls.length === 1) {

                        /** Just 1 not null link, no need to let the user select */
                        if (response.ssoLoginUrls[0].url !== null) {

                            window.location.href = response.ssoLoginUrls[0].url;

                        } else {

                            setIdentityCheckLoading(false);
                        }
                    }
                    else {
                        /** We have a list of urls, let the user select */
                        setSsoUrls(response.ssoLoginUrls);
                        setIdentityCheckLoading(false);
                    }

                } else {

                    setIdentityCheckLoading(false);
                }

            } else {

                console.warn(response.error);
            }

        } catch (error) {

            console.warn(error);

        }

        setEmail(identity);
    }

    const sendLoginVerification = async () => {

        setVerificationLoading(true);
        setShowLoginButton(false);

        setGeneralError(null);

        try {

            const signupResponse = await post(`authentication/login-generate-otp`, {
                email,
            });

            if (signupResponse && signupResponse.success) {

                // No action needed, just ask user input for the code
                enqueueSnackbar(translatePlaceholder("VERIFICATION_SEND_ALERT"), { variant: 'success'});

                setShowLoginButton(true);

                setGeneralError(null);

            } else if (signupResponse && signupResponse.errors) {

                const errors = signupResponse.errors;

                for (const errorField in errors) {

                    let [error] = errors[errorField];

                    setOtpError(error);
                }
            } else {
                setGeneralError( translatePlaceholder("UNABLE_TO_PROCESS") );
            }

        } catch (error) {

            setGeneralError( translatePlaceholder("UNABLE_TO_PROCESS") );
            console.log('Error', error);
        }

        setVerificationLoading(false);
    }

    useEffect(() => {

        if (Boolean(emailPlaceholder))

            setEmailFieldTouched(true);

    }, [emailPlaceholder])

    useEffect( () => {

        if (Boolean(email) && !ssoUrls && !identityCheckLoading) {

            sendLoginVerification();
        }

    }, [email, ssoUrls, identityCheckLoading])

    const authenticate = async () => {

        dispatch(setAuthLoading(true))

        const authToken = getFromCookies(TOKEN_NAME);
        

        const refreshToken = getFromCookies(REFRESH_TOKEN_NAME);

        if (authToken || refreshToken) {

            try {

                const GETParams = new URLSearchParams(window.location.search);

                const authParams: ExternalAuthParams = {}

                const externalAuthKey = GETParams.get(UTM.ExternalAuthKey);

                if (Boolean(externalAuthKey)) {

                    authParams.externalAuthKey = externalAuthKey as string;
                }

                const authSearchParams = new URLSearchParams(authParams as string)

                const response = await get(`authentication/info?${authSearchParams}`);

                getNewrelic(nr => nr.setUserId(String(response.user.id)));

                if (!response.error) {

                    if (Boolean(externalAuthKey)) {

                        history.push(urls.authSuccess);

                        dispatch(setAuthLoading(false))

                        return;
                    }

                    dispatch(saveUserData(response))

                    addToCookies(CookieKeys.AuthToken, response.token, response.expires * 1000);

                } else {

                    removeFromCookies(TOKEN_NAME);
                }

            } catch (error) {

                console.warn(error)
            }

        } else {

            dispatch(toggleLogin())
        }

        dispatch(setAuthLoading(false))
    }

    const handleLoginCallback = (userData?: any) => {

        typeof loginCallback === "function" && (loginCallback(userData));

        dispatch(setLoginCallback(null))
    }

    const login = async () => {

        dispatch(appActions.setLoginLoading(true));

        setEmailError("");

        setOtpError("");

        const requestParams: LoginParams = {
            email: email as string,
            otp,
            client_id: WEB_PRESENTATIONS_CLIENT_ID
        }

        if (Boolean(externalAuthKey)) {

            requestParams.externalAuthKey = externalAuthKey as string;
        }

        const userData = await post('authentication/login', requestParams);

        if (userData.error) {

            if (typeof userData.error === 'object') {

                // Probably a TypeError: Failed to fetch, let's display a user readable error
                setEmailError('Something went wrong, please try again later or contact support if this issue keeps persisting...');

            } else {

                setEmailError(userData.error);
            }

            dispatch(appActions.setLoginLoading(false));

            return;
        }

        /**
         * If the External auth key is set, we're logging in through the add-in from a web popup.
         * This means we don't want the entire webapp to be rendered
         * so we return early to keep the spinner/loader active.
         *
         * The loginCallback with the retrieved user data is called so we can emit
         * the user data to the socket room the add-in is subscribed to.
         */
        if (Boolean(externalAuthenticationKey)) {

            handleLoginCallback(userData)

            history.push(urls.authSuccess);

            dispatch(appActions.setLoginLoading(false));

            return;
        }

        dispatch(appActions.setLoginLoading(false));

        dispatch(saveUserData(userData))

        const userEnvApiUrl = userData?.token && userData.token?.split('.')[1] && atob(userData.token?.split('.')[1]);
        const apiURL = userEnvApiUrl && JSON.parse(userEnvApiUrl).api;
        const replacementRedirect = process.env.API_REDIRECTS && apiURL && JSON.parse(process.env.API_REDIRECTS)[apiURL];

        if (process.env.API_URL !== apiURL && !!replacementRedirect) {
            window.location.href = `${window.location.href.replace(window.location.origin, replacementRedirect)}?loginToken=${userData.token}`;
        }
        /** in 1 month */
		const expDate = new Date().getTime() + (1000 * 60 * 60 * 24 * 30);
        addToCookies(TOKEN_NAME, userData.token, expDate);

        fetchAppData();

        getNewrelic(nr => nr.setUserId(String(userData.user.id)));

        addToCookies(CookieKeys.AuthToken, userData.token, userData.expires * 1000)

        if (userData.refreshToken) {
            /** in 1 month */
            const expDate = new Date().getTime() + (1000 * 60 * 60 * 24 * 30);
            addToCookies(REFRESH_TOKEN_NAME, userData.refreshToken, expDate);
        
        }

        handleLoginCallback();

        dispatch(toggleLogin());

        dispatch(appActions.setLoginLoading(false));
    }

    useEffect(() => {

        authenticate();

        selectEmailField();

    }, [])

    const isLoggingIn = (loginLoading || currentUser);

    const identityProviderLoginUrl = (providerName: string): string => {
        return process.env.API_URL + 'identity/authorize/' + providerName.toLowerCase();
    }

    const IdentityProvidersLogin = () => {
        const SsoGoogleLoginUrl = identityProviderLoginUrl('google');
        const SsoLinkedinLoginUrl = identityProviderLoginUrl('linkedin');

        return <>
            <Box my={4} className={classes.socialButtons} id="social_login_div">
                <AppButton
                    href={SsoGoogleLoginUrl}
                    as={AppButtonVariant.Outlined}
                    data-elscreen={DataElScreen.Login}
                    data-elaction={DataElAction.SignInWithGoogle}
                    data-elposition={DataElPosition.LoginDialog}
                    data-testid={testIds.SSO_BUTTON_GOOGLE}
                    startIcon={<SocialLoginGoogle
                        className={classes.socialSVG}
                        height={24}
                    />}
                >
                    {translatePlaceholder('SSO_GOOGLE')}
                </AppButton>

                <AppButton
                    href={SsoLinkedinLoginUrl}
                    as={AppButtonVariant.Outlined}
                    data-elscreen={DataElScreen.Login}
                    data-elaction={DataElAction.SignInWithLinkedIn}
                    data-elposition={DataElPosition.LoginDialog}
                    data-testid={testIds.SSO_BUTTON_LINKEDIN}
                    startIcon={<SocialLoginLinkedin
                        className={classes.socialSVG}
                        height={21}
                    />}
                >
                    {translatePlaceholder('SSO_LINKEDIN')}
                </AppButton>
                <Typography textAlign="center" my={2}>
                    {translatePlaceholder('OR')}
                </Typography>
            </Box>
        </>
    }

    const handlePressedKeyForEmail = (e: KeyboardEvent) => {

        if (e.key === 'Enter' || e.key === 'Tab') {

            e.preventDefault();

            checkIdentity();
        }
    }

    const checkIdentity = () => {

        setEmailFieldTouched(true);

        if (validateEmailPlaceholder(emailPlaceholder)) {

            checkIdentityProvider(emailPlaceholder as string);
        }
    }

    const focusOtpField = () => {

        otpFieldRef.current && otpFieldRef.current.focus();
    }

    const focusEmailField = () => {

        emailFieldRef.current && emailFieldRef.current.focus();
    }

    const selectEmailField = () => {

        emailFieldRef.current && emailFieldRef.current.select();
    }

    const handleSingleSignOnClick = (url: string) => {

        if (url === null) {

            setSsoUrls(null);
            return;
        }

        setIdentityCheckLoading(true);

        window.location.href = url;
    }

    const shouldShowContinueButton = (!Boolean(email) || Boolean(identityCheckLoading) || Boolean(verificationLoading));

    const theme = useTheme();

    const showBackdrop = (isEmpty(currentUser) && !authLoading);

    return (
        <>
            <SignupBackdrop
                open={showBackdrop}>
                <DialogContent className={classes.loginContainer}>
                    <Switch>
                        <Route path={urls.authSuccess}>
                            <AuthSuccessComponent />
                        </Route>
                        <Route>
                            {isEmpty(currentUser) && <div data-testid={testIds.LOGIN_PANEL} />}
                            {isLoggingIn && <AuthPendingComponent />}
                            {!isLoggingIn && <Box className={classes.loginInnerContainer}>
                                <SendstepsLogo />
                                <Box my={4}>
                                    <Typography textAlign="center">
                                        {!shouldShowContinueButton && translatePlaceholder('LOGIN_WITH_EXTENDED')}
                                        {shouldShowContinueButton &&  translatePlaceholder('LOGIN_WITH')}
                                    </Typography>
                                </Box>
                                {!Boolean(email) && <IdentityProvidersLogin />}
                                {Boolean(email) && (
                                    <Chip
                                        disabled={identityCheckLoading || verificationLoading}
                                        classes={{
                                            root: classes.emailChip,
                                            label: classes.emailChipLabel,
                                            deleteIcon: classes.emailChipDeleteIcon,
                                        }}
                                        sx={{
                                            color: alpha(theme.palette.common.black, .75),
                                        }}
                                        icon={<AccountCircleIcon />}
                                        label={<Typography noWrap>
                                            {email}
                                        </Typography>}
                                        onDelete={() => {
                                            setEmail(null);
                                            setSsoUrls(null);
                                            setShowLoginButton(false);
                                            setEmailPlaceholder('');
                                        }}
                                        variant="outlined"
                                    />
                                )}
                                {!Boolean(email) && (
                                    <Slide direction="left" in={!Boolean(email)}>
                                        <div>
                                            <AppInput
                                                variant="standard"
                                                disabled={identityCheckLoading || verificationLoading}
                                                value={emailPlaceholder}
                                                data-testid={testIds.LOGIN_EMAIL}
                                                onChange={e => setEmailPlaceholder(e?.target.value.trim())}
                                                onBlur={(e) => validateEmailPlaceholder()}
                                                onKeyDown={(e: KeyboardEvent) => handlePressedKeyForEmail(e)}
                                                error={Boolean(emailError) && emailFieldTouched}
                                                fullWidth
                                                autoFocus
                                                placeholder={translatePlaceholder('LOGIN_ENTER_YOUR_EMAIL')}
                                                size="small"
                                                inputProps={{
                                                    ref: emailFieldRef,
                                                }}
                                            />
                                        </div>
                                    </Slide>
                                )}
                                {Boolean(emailError) && emailFieldTouched && Boolean(emailPlaceholder) && (
                                    <Box mt={1}>
                                        <ErrorText error={emailError} />
                                    </Box>
                                )}
                                {ssoUrls &&
                                    <Box pt={2}>
                                        <AppDropdown
                                            placeholder="Select your login method or location"
                                            loading={identityCheckLoading}
                                            disabled={identityCheckLoading}
                                            values={ssoUrls.map((item: AuthSsoUrl) => item.location)}
                                            onChange={(_, index) => handleSingleSignOnClick(ssoUrls[index].url)}
                                        />
                                    </Box>
                                }
                                {!ssoUrls && !Boolean(shouldShowContinueButton) && !Boolean(verificationLoading) && (
                                    <Slide direction="left" in={!shouldShowContinueButton}>
                                        <Box mt={3}>
                                            <Box className={classes.helpMessage}>
                                                {translatePlaceholder('VERIFICATION_SEND_MSG').replace('{email}', emailPlaceholder)}
                                                <AppLink
                                                    className={classes.resendLink}
                                                    onClick={() => sendLoginVerification()}
                                                    data-eltype={DataElType.Link}
                                                    data-elaction={DataElAction.ResendLoginCode}
                                                    data-elscreen={DataElScreen.Login}>
                                                    {translatePlaceholder("BTN_RESEND_VERIFICATION")}
                                                </AppLink>
                                                <br/>{translatePlaceholder('OR_ENTER_YOUR_PASSWORD')}
                                                <br/>{translatePlaceholder('EMAIL_NOT_REGISTERED')}&nbsp;
                                                <a
                                                    className={classes.signupLink}
                                                    data-eltype="link"
                                                    data-elaction="sign_up_link"
                                                    data-elscreen="login"
                                                    href={urls.websiteSignupPage}>
                                                    {translatePlaceholder('CREATE_ACCOUNT')}
                                                </a>
                                            </Box>
                                            <AppInput
                                                value={otp}
                                                data-testid={testIds.LOGIN_PASSWORD}
                                                onChange={e => setOtp(e.target.value)}
                                                onBlur={() => validateOtp()}
                                                onKeyPress={e => e.key === 'Enter' ? attemptLogin() : null}
                                                placeholder={translatePlaceholder('FORM_OTP_CODE')}
                                                size="small"
                                                autoFocus
                                                autoComplete="current-otp"
                                                fullWidth
                                                error={Boolean(otp) && Boolean(otpError)}
                                                type="password"
                                                inputProps={{
                                                    ref: otpFieldRef,
                                                }}
                                            />
                                            {Boolean(otp) && otpError && (
                                                <Box mt={1}>
                                                    <ErrorText error={otpError} />
                                                </Box>
                                            )}
                                        </Box>
                                    </Slide>
                                )}
                                {Boolean(ssoUrls?.length) || shouldShowContinueButton && (
                                    <Box mt={3}>
                                        <Button
                                            className={classes.loginButton}
                                            disabled={identityCheckLoading}
                                            onClick={() => checkIdentity()}
                                            fullWidth
                                            data-eltype="button"
                                            data-elaction="click_login"
                                            data-elscreen="login"
                                            variant="contained"
                                            data-testid={testIds.LOGIN_SUBMIT_BUTTON}
                                            color="primary" >
                                            {(identityCheckLoading || verificationLoading) && <ButtonLoader inline={true} />}
                                            {!identityCheckLoading && !verificationLoading && translatePlaceholder('BTN_CONTINUE')}
                                        </Button>
                                    </Box>
                                )}
                                {generalError && (
                                    <Box mt={1}>
                                        <ErrorText error={generalError} />
                                    </Box>
                                )}
                                {Boolean(showLoginButton) && (
                                    <Box mt={3}>
                                        <Button
                                            className={classes.loginButton}
                                            disabled={identityCheckLoading}
                                            onClick={() => attemptLogin()}
                                            fullWidth
                                            data-eltype="button"
                                            data-elaction="click_login"
                                            data-elscreen="login"
                                            variant="contained"
                                            data-testid={testIds.LOGIN_SUBMIT_BUTTON}
                                            color="primary" >
                                            {translatePlaceholder('LOGIN_NOW')}
                                        </Button>
                                    </Box>
                                )}
                            </Box>}
                        </Route>
                    </Switch>
                </DialogContent>
            </SignupBackdrop>
        </>
    );
};

export default LoginDialog;