import { CountryList, LivePresentationData, Participant, ParticipantMessage, ParticipantVote, ScoreObject, SessionData } from "Types/playTypes";
import { ApiVoteResult } from 'Types/presentationTypes';

export const setVoteResults = (voteId: number, responses: ApiVoteResult[]) => ({
    type: 'SET_VOTE_RESULTS',
    voteId,
    responses,
})

export const setPlayslides = (livePresentationData: LivePresentationData) => ({
    type: 'SET_PLAY_SLIDES',
    livePresentationData,
})

export const participantJoined = (newParticipant: Participant) => ({
    type: 'PARTICIPANT_JOINED',
    newParticipant
})

export const setNewMessagesAreIn = (newMessagesAreIn: boolean) => ({
    type: 'SET_NEW_MESSAGES_ARE_IN',
    newMessagesAreIn
})

export const setSelectedCountry = (countryIsoCode: string) => ({
    type: 'SET_SELECTED_COUNTRY',
    countryIsoCode
})

export const setCountries = (countries: CountryList) => ({
    type: 'SET_COUNTRIES',
    countries,
})

export const setAlreadyJoinedParticipants = (participants: Participant[]) => ({
    type: 'SET_EXISTING_PARTICIPANTS',
    participants
})

export const participantLeft = (participantId: number) => ({
    type: 'PARTICIPANT_LEFT',
    participantId,
})

export const setSessionRunId = (id: number) => ({
    type: 'SET_SESSION_RUN_ID',
    id
})

export const togglePlay = () => ({
    type: 'TOGGLE_PLAY'
})

export const toggleEditPresentation = (isOpen: boolean) => ({
    type: 'TOGGLE_EDIT_PRESENTATION',
    isOpen
})

export const toggleStop = () => ({
    type: 'TOGGLE_STOP'
})

export const togglePause = () => ({
    type: 'TOGGLE_PAUSE'
})

export const setQuizResults = (quizResults: any) => ({
    type: 'SET_QUIZ_RESULTS',
    quizResults
})

export const setSessionData = (sessionData: SessionData) => ({
    type: 'SET_SESSION_DATA',
    sessionData
})

export const updateVotes = (newVotes: ParticipantVote[]) => ({
    type: 'UPDATE_VOTES',
    newVotes
})

export const createNewMessages = (newMessages: ParticipantMessage[]) => ({
    type: 'CREATE_NEW_MESSAGES',
    newMessages
});

export const updateMessages = (messageRoundId: number, messages: ParticipantMessage[]) => ({
    type: 'UPDATE_MESSAGES',
    messageRoundId,
    messages
})

export const removeMessages = ( messages: ParticipantMessage[]) => ({
    type: 'REMOVE_MESSAGES',
    messages,
})

export const setRunTimers = (runTimers: boolean) => ({
    type: "SET_RUN_TIMERS",
    runTimers
})

export const clearPlayState = () => ({
    type: 'CLEAR_PLAY_STATE'
})

export const toggleStreakScores = () => ({
    type: 'TOGGLE_STREAK_SCORES'
})

export const setQuizRanksBySlideKey = (newRanks: ScoreObject[]) => ({
    type: 'SET_QUIZ_RANKS_BY_SLIDE_KEY',
    newRanks
})

export const showEndSlideTransition = (show: boolean) => ({
    type: 'SHOW_END_SLIDE_TRANSITION',
    show
})

export const toggleMissingPresentationFeatures = () => ({
    type: 'TOGGLE_MISSING_PRESENTATION_FEATURES'
})

export const setNewEditor = (newEditor: string | null) => ({
    type: 'SET_NEW_EDITOR',
    newEditor
})
