import { Box } from '@mui/system';
import FullSizeContainer from 'Atomic/atoms/FullSizeContainer';
import { parseJsonSafe } from 'Scripts/parserHelper';
import { getPlaySlideType } from 'Scripts/slideHelper';
import { ScreenSizes } from 'Types/appTypes';
import { PlaySlideType } from 'Types/playTypes';
import { SlideType } from 'Types/presentationTypes';
import { SlideShape, SlideTypes } from 'Types/slideTypes';
import React from 'react';

interface Props {
    slide: SlideType;
    shape: SlideShape;
}

const PreviewImageSlideShape = ({
    slide,
    shape
}: Props) => {

    const imageIsOnBackground = (
        (shape.height === ScreenSizes.Height1080P) &&
        (shape.width === ScreenSizes.Width1080P)
    );

    const isImageSlideType = (getPlaySlideType(slide as PlaySlideType) || slide.type) === SlideTypes.Image;

    const jss = parseJsonSafe(shape.jss);

    return (
        <FullSizeContainer>
            {Boolean(shape.filename || slide.filename) && (
                <>
                    <Box
                        style={{
                            height: '100%',
                            width: '100%',
                            backgroundImage: `url(${shape.filename || slide.filename})`,
                            backgroundSize: slide.filenameStyle,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            opacity: slide.backgroundOpacity || 1,
                            ...jss
                        }}
                    />
                </>
            )}
        </FullSizeContainer >
    );
};

export default PreviewImageSlideShape;