import { Box, CircularProgress } from '@mui/material';
import { getFromCookies } from 'Scripts/cookieHelper';
import { TOKEN_NAME } from 'Scripts/globals';
import { useInterval } from 'Scripts/intervalHook';
import React, { RefObject, useRef, useState } from 'react';
import { classes } from './style.css';
interface WordCloudComponentProps {
    demoMode?: boolean;
    messageRoundId?: number;
}

enum WordCloudUrlParams {
    MessageRoundId = 'messageRoundId',
    Token = 'token',
    DemoMode = 'demo',
}

const showLoadingMessageDelayMs = 3000;

const WordCloudComponent = ({
    demoMode = false,
    messageRoundId,
}: WordCloudComponentProps) => {

    const cloudRef = useRef<RefObject<HTMLIFrameElement>>();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [ showLoader, setShowLoader ] = useState<boolean>(false);

    const token = getFromCookies(TOKEN_NAME) as string;

    const urlParams = new URLSearchParams();

    if (!demoMode) {

        urlParams.set(WordCloudUrlParams.Token, token);

        urlParams.set(WordCloudUrlParams.MessageRoundId, String(messageRoundId));

    } else {

        urlParams.set(WordCloudUrlParams.DemoMode, String(1));
    }

    const wordCloudUrl = `${process.env.CHARTS_URL}/wordcloud?${urlParams}`;


    useInterval(() => {

        setShowLoader(true);

    }, (!showLoader && isLoading) ? showLoadingMessageDelayMs : null )

    return (
        <div className={classes.container}>
            {isLoading && showLoader && (
                <Box
                    className={classes.loader}
                    display="flex"
                    alignItems="center"
                    justifyContent="center">
                    <CircularProgress
                        className={classes.loadingIcon}
                    />
                </Box>
            )}
            <iframe
                ref={cloudRef as any}
                onLoad={() => setIsLoading(false)}
                onError={(error) => console.log({error})}
                height="100%"
                width="100%"
                src={wordCloudUrl}
            />
        </div>
    );
}

export default WordCloudComponent;