import { isEmpty } from 'lodash';
import { AnyAction } from 'redux';
import { numberizeSlideIndices } from 'Scripts/mathHelper';
import { getPlaySlideType } from 'Scripts/slideHelper';
import { Participant, ParticipantMessage, ParticipantVote, PlaySlideList, PlayState } from 'Types/playTypes';
import { ApiVoteAnswerResult } from 'Types/presentationTypes';
import { SlideTypes } from 'Types/slideTypes';

const initialState: PlayState = {
    joinedParticipants: [],
    sessionRunId: null,
    runTimers: true,
    streakScores: true,
    newMessagesAreIn: false,
    votes: {},
    messageRoundId: null,
    messages: {},
    quizRanksBySlideKey: {},
    quizResults: null,
    leftParticipants: [],
    sessionData: null,
    playOpen: false,
    stopOpen: false,
    quizPaused: false,
    toggleEditPresentationOpen: false,
    playEndSlideTransition: false,
    playOpenWithResults: false,
    missingPresentationFeaturesOpen: false,
    countries: {},
    selectedCountry: '',
    playSlides: {},
    messageCountsByMessageRoundId: {},
    voteCountsByVoteId: {},
    voteResults: {},
    newEditor: null,
    fonts: {},
}

export const playReducer = (state: PlayState = initialState, action: AnyAction) : PlayState => {
    switch (action.type) {
        case 'SET_NEW_EDITOR': {
            return {
                ...state,
                newEditor: action.newEditor
            }
        }
        case 'SET_VOTE_RESULTS': {

            const { voteId, responses } = action;

            return {
                ...state,
                voteResults: {
                    ...state.voteResults,
                    [voteId]: ((responses || []) as ApiVoteAnswerResult[]).reduce((responseMap, response: ApiVoteAnswerResult) => {
                        /** Map the values by voteId -> answer code */
                        responseMap[response.answerCode] = response;

                        return responseMap;

                    }, {} as Record<string, ApiVoteAnswerResult>),
                }
            }
        }
        case 'SET_PLAY_SLIDES': {

            const { contentSlides = {}, votes = {}, voteAnswers = {}, messagerounds = {}, tables = {}, shapes = {}, fonts = {} } = action.livePresentationData;

            const votesWithAnswers = { ...votes }

            Object.keys(voteAnswers).forEach(voteId => {

                votesWithAnswers[voteId].options = voteAnswers[voteId];
            })

            for (const contentSlideId in contentSlides) {

                const slideId = contentSlides[contentSlideId].slideId;

                if (!Boolean(contentSlides[contentSlideId])) {

                    continue;
                }

                if( Boolean(tables[slideId])) {

                    contentSlides[contentSlideId].table = tables[slideId];
                }

                if(Boolean(shapes[slideId])) {

                    contentSlides[contentSlideId].shapes = shapes[slideId];
                }
            }

            for(const messageroundId in messagerounds) {

                const slideId = messagerounds[messageroundId].slideId;

                if (Boolean(shapes[slideId])) {

                    messagerounds[messageroundId].shapes = shapes[slideId];
                }
            }

            for(const voteId in votes) {

                const slideId = votes[voteId].slideId;

                if (Boolean(shapes[slideId])) {

                    votes[voteId].shapes = shapes[slideId];
                }
            }

            const playSlides = numberizeSlideIndices({
                ...contentSlides,
                ...votesWithAnswers,
                ...messagerounds,
            }) as PlaySlideList;

            return {
                ...state,
                playSlides,
                fonts,
            }
        }
        case 'SHOW_END_SLIDE_TRANSITION': {
            return {
                ...state,
                playEndSlideTransition: action.show
            }
        }
        case 'SET_COUNTRIES': {

            return {
                ...state,
                countries: action.countries
            }
        }
        case 'SET_SELECTED_COUNTRY': {

            return {
                ...state,
                selectedCountry: action.countryIsoCode,
            }
        }
        case 'TOGGLE_PLAY_WITH_RESULTS': {
            return {
                ...state,
                playOpenWithResults: action.isOpen
            }
        }
        case 'SET_NEW_MESSAGES_ARE_IN': {
            return {
                ...state,
                newMessagesAreIn: action.newMessagesAreIn
            }
        }
        case 'UPDATE_VOTES': {

            const { newVotes = [] } = action

            const updatedVotes = { ...state.votes }

            const newVoteCounts = { ...state.voteCountsByVoteId }

            newVotes.forEach((vote: ParticipantVote) => {

                newVoteCounts[vote.voteId] = (newVoteCounts[vote.voteId] || new Set());


                if (isEmpty(updatedVotes[vote.voteId])) {

                    updatedVotes[vote.voteId] = {}
                }

                if(Boolean(vote.edited)) {

                    delete updatedVotes[vote.voteId][vote.id]

                    newVoteCounts[vote.voteId].delete(vote.id);

                } else {

                    updatedVotes[vote.voteId][vote.id] = {
                        ...vote
                    }

                    newVoteCounts[vote.voteId].add(vote.id);
                }
            })

            return {
                ...state,
                votes: { ...updatedVotes },
                voteCountsByVoteId: newVoteCounts,
            }
        }
        case 'CREATE_NEW_MESSAGES': {

            const updatedMessages = { ...state.messages }

            if(isEmpty(action.newMessages)) {

                return {
                    ...state,
                    messages: { ...updatedMessages}
                }
            }

            const [ firstMessage ] = action.newMessages;

            const slideKey = firstMessage.messageRoundId

            if(isEmpty(updatedMessages[slideKey])) {

                updatedMessages[slideKey] = {}
            }

            const newMessageCounts = { ...state.messageCountsByMessageRoundId }

            action.newMessages.forEach((newMessage: ParticipantMessage) => {

                newMessageCounts[newMessage.messageRoundId] = (newMessageCounts[newMessage.messageRoundId] || new Set());

                newMessageCounts[newMessage.messageRoundId].add(newMessage.id);

                updatedMessages[slideKey][newMessage.id] = { ...newMessage }
            })

            return {
                ...state,
                messages: {
                    ...updatedMessages
                },
                newMessagesAreIn: true,
                messageCountsByMessageRoundId: newMessageCounts,
            }
        }
        case 'UPDATE_MESSAGES': {

            const { messageRoundId, messages = [] } = action

            const { playSlides } = state;

            const activeSlide = playSlides[messageRoundId];

            const slideType = getPlaySlideType(activeSlide);

            const newMessageCounts = { ...state.messageCountsByMessageRoundId }

            /* If messages on a wordcloud/upvoting slide update, a different flow is handled */
            if ([
                SlideTypes.Wordcloud,
                SlideTypes.QA,
            ].includes(slideType)) {

                const newMessages = { ...state.messages }

                messages.forEach((message: ParticipantMessage) => {

                    newMessageCounts[message.messageRoundId] = (newMessageCounts[message.messageRoundId] || new Set());

                    newMessageCounts[message.messageRoundId].add(message.id);

                    newMessages[messageRoundId][message.id] = {
                        ...message
                    }
                })

                return {
                    ...state,
                    messages: { ...newMessages },
                    newMessagesAreIn: true,
                    messageCountsByMessageRoundId: newMessageCounts,
                }

            } else {

                const updatedMessages = { ...state.messages }

                // First, clear all existing messages for the current messageRoundId
                // because all messages to be shown are provided in the newMessages array
                updatedMessages[messageRoundId] = {}

                // Now add the new messages
                messages.forEach((message: ParticipantMessage) => {

                    newMessageCounts[message.messageRoundId] = (newMessageCounts[message.messageRoundId] || new Set());

                    newMessageCounts[message.messageRoundId].add(message.id);

                    updatedMessages[message.messageRoundId][message.id] = {
                        ...message
                    }
                })

                return {
                    ...state,
                    messages: { ...updatedMessages },
                    newMessagesAreIn: true,
                    messageCountsByMessageRoundId: newMessageCounts,
                }
            }
        }
        case 'REMOVE_MESSAGES': {

            const updatedMessages = { ...state.messages }

            const newMessageCounts = { ...state.messageCountsByMessageRoundId }

            if(!isEmpty(action.messages)) {

                action.messages.forEach((message: ParticipantMessage) => {

                    if(!isEmpty(updatedMessages[message.messageRoundId])) {

                        newMessageCounts[message.messageRoundId] = (newMessageCounts[message.messageRoundId] || new Set());

                        newMessageCounts[message.messageRoundId].delete(message.id);

                        delete updatedMessages[message.messageRoundId][message.id]
                    }
                })
            }

            return {
                ...state,
                messages: {
                    ...updatedMessages
                },
                newMessagesAreIn: true,
                messageCountsByMessageRoundId: newMessageCounts,
            }
        }
        case 'CLEAR_PLAY_STATE': {
            return {
                ...initialState,
            }
        }
        case 'TOGGLE_STOP': {
            return {
                ...state,
                stopOpen: !state.stopOpen
            }
        }
        case 'TOGGLE_PLAY': {
            return {
                ...state,
                playOpen: !state.playOpen
            }
        }
        case 'TOGGLE_PAUSE': {
            return {
                ...state,
                quizPaused: !state.quizPaused
            }
        }
        case 'SET_SESSION_DATA': {
            return {
                ...state,
                sessionData: action.sessionData,
                sessionRunId: action.sessionData.sessionRunId
            }
        }
        case 'TOGGLE_STREAK_SCORES': {
            return {
                ...state,
                streakScores: !state.streakScores
            }
        }
        case 'SET_QUIZ_RESULTS': {
            return {
                ...state,
                quizResults: { ...action.quizResults }
            }
        }
        case 'SET_RUN_TIMERS': {
            return {
                ...state,
                runTimers: action.runTimers
            }
        }
        case 'TOGGLE_EDIT_PRESENTATION': {
            return {
                ...state,
                toggleEditPresentationOpen: action.isOpen
            }
        }
        case 'SET_EXISTING_PARTICIPANTS': {

            return {
                ...state,
                joinedParticipants: [
                    ...action.participants
                ]
            }
        }
        case 'PARTICIPANT_JOINED': {

            let newParticipants = [...state.joinedParticipants]

            let newLeftParticipants = [...state.leftParticipants]

            /*
                Participant joined, with participantId
            */

            let newParticipant = action.newParticipant;


            /* Participant rejoining, check the leftparticipants for entries */

            const leftPids = newLeftParticipants.map((participant: Participant) => participant.participantId);

            const leftPidIndex = leftPids.indexOf(newParticipant.participantId)

            if (leftPidIndex !== -1) {

                newParticipant = newLeftParticipants[leftPidIndex];
                newParticipant.joinTime = new Date();

                /* Remove the left participant from left participants */

                newLeftParticipants.splice(leftPidIndex, 1)

            }

            /*
                In some cases the Participant is logging in on the response site without filling in info...
                They will fill in the info eventually
            */

            const joinedPids = newParticipants.map((participant: Participant) => participant.participantId);

            const joinedPidIndex = joinedPids.indexOf(newParticipant.participantId)

            if (joinedPidIndex !== -1) {

                newParticipants[joinedPidIndex] = newParticipant

            } else {

                newParticipants.push({ ...newParticipant });
            }

            return {
                ...state,
                joinedParticipants: newParticipants,
                leftParticipants: newLeftParticipants
            }
        }
        case 'PARTICIPANT_LEFT': {

            const currentParticipants = [...state.joinedParticipants]

            const leftId = `${action.participantId}`

            const joinedIds = currentParticipants.map((participant: Participant) => `${participant.participantId}`)

            const leftParticipantIndex = joinedIds.indexOf(leftId)

            const newLeftParticipants = [...state.leftParticipants]

            if (leftParticipantIndex !== -1) {
                const leftParticipant = currentParticipants.splice(leftParticipantIndex, 1)
                newLeftParticipants.push(leftParticipant[0]);
            }

            return {
                ...state,
                joinedParticipants: currentParticipants,
                leftParticipants: newLeftParticipants
            }
        }
        case 'SET_SESSION_RUN_ID': {
            return {
                ...state,
                sessionRunId: action.id
            }
        }
        case 'SET_QUIZ_RANKS_BY_SLIDE_KEY': {
            return {
                ...state,
                quizRanksBySlideKey: action.newRanks,
            }
        }
        case 'TOGGLE_MISSING_PRESENTATION_FEATURES': {
            return {
                ...state,
                missingPresentationFeaturesOpen: !state.missingPresentationFeaturesOpen
            }
        }
        default:
            return state
    }
}